import classes from "./app.module.css";

// Panel Component
const Panel = () => <h2>I&apos;m a Panel !!</h2>;

// App Component
const component = () => {
  return (
    <div className={classes.rouge}>
      <h1>Hello, ESBUILD!</h1>
      <Panel></Panel>
    </div>
  );
};

export default component;
